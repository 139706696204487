
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import { onBeforeMount, ref, defineComponent, inject } from "vue";
import { apiGet, apiPost } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import Swal from "sweetalert2";

export default defineComponent({
  name: "ActivityDetail",
  components: {
    HourglassLoader,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);

    const RequestConfirmation = inject("RequestConfirmation");

    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/withdrawals/transactions/${route.params.transactionId}`
        );
        data.value = response.data.data.transaction;
        Toast.fire({
          icon: "success",
          title: "Transaction Detail Fetched Successfully",
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const ApproveTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/approve`
        );
        Toast.fire({
          icon: "success",
          title: "Transaction Approved Successfully",
        }).then();
        await RefreshData();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const ProcessTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/retry`,
          { status: "PROCESSING" }
        );
        Toast.fire({
          icon: "info",
          title: "Transaction Processing",
        }).then();
        await RefreshData();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const ManualTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/manual`,
          { status: "MANUAL" }
        );
        Toast.fire({
          icon: "info",
          title: "Transaction now marked successful",
        }).then();
        await RefreshData();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve manually: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const DeclineTransaction = async () => {
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Enter Reason/Comment for declining",
        inputPlaceholder: "Type your reason/comment here...",
        inputAttributes: {
          "aria-label": "Type your reason/comment here",
        },
        showCancelButton: true,
      });

      if (!text) {
        return;
      }

      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/decline`,
          { comments: text, status: "FAILED" }
        );
        Toast.fire({
          icon: "success",
          title: "Transaction Declined Successfully",
        }).then();
        await RefreshData();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to decline: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      ApproveTransaction,
      DeclineTransaction,
      ProcessTransaction,
      ManualTransaction,
      RequestConfirmation,
      route,
      data,
      loading,
    };
  },
});
