import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center py-3" }
const _hoisted_2 = { class: "btn-toolbar" }
const _hoisted_3 = {
  key: 0,
  class: "row"
}
const _hoisted_4 = { class: "col-12 pb-5" }
const _hoisted_5 = { class: "card card-body border-light shadow-sm" }
const _hoisted_6 = { class: "text-center" }
const _hoisted_7 = { class: "fw-bolder" }
const _hoisted_8 = { class: "row justify-content-center text-center py-3" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "row text-center py-3" }
const _hoisted_12 = { class: "col-12 col-md-4" }
const _hoisted_13 = { class: "col-12 col-md-4" }
const _hoisted_14 = { class: "col-12 col-md-4" }
const _hoisted_15 = { class: "row text-center py-3" }
const _hoisted_16 = { class: "col-12 col-md-6" }
const _hoisted_17 = { class: "col-12 col-md-6" }
const _hoisted_18 = { class: "col-12 pb-5" }
const _hoisted_19 = { class: "card card-body border-light shadow-sm" }
const _hoisted_20 = { class: "row text-center py-3" }
const _hoisted_21 = { class: "col-4" }
const _hoisted_22 = { class: "col-4" }
const _hoisted_23 = { class: "col-4" }
const _hoisted_24 = { class: "col-12 pb-5" }
const _hoisted_25 = { class: "card card-body border-light shadow-sm" }
const _hoisted_26 = { class: "row justify-content-center text-center py-3" }
const _hoisted_27 = { class: "col-6" }
const _hoisted_28 = { class: "col-6" }
const _hoisted_29 = { class: "row mt-4 gy-4 border justify-content-center text-center py-3" }
const _hoisted_30 = { class: "text-gray" }
const _hoisted_31 = { class: "col-12 mt-5 mt-md-0" }
const _hoisted_32 = { class: "card card-body border-light shadow-sm" }
const _hoisted_33 = { class: "card card-body border-light shadow-sm table-wrapper table-responsive pt-0" }
const _hoisted_34 = { class: "table table-hover" }
const _hoisted_35 = {
  key: 1,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!

  return (_openBlock(), _createBlock(_component_HourglassLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_1, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "d-block" }, [
            _createElementVNode("h2", { class: "h4" }, "Activity Details")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-outline-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)))
            }, "Reload")
          ])
        ]),
        (_ctx.data)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _cache[2] || (_cache[2] = _createElementVNode("h4", { class: "text-gray" }, "Created At", -1)),
                    _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.data.created_at), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _cache[3] || (_cache[3] = _createElementVNode("h4", { class: "text-gray" }, "Status", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.status), 1)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "text-gray" }, "WalletId", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.fiat_wallet_id), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "text-gray" }, "UserId", -1)),
                      _createVNode(_component_router_link, {
                        to: `/users/${_ctx.data.User.id}`
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h6", null, _toDisplayString(_ctx.data.User.id), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "text-gray" }, "Email", -1)),
                      _createVNode(_component_router_link, {
                        to: `/users/${_ctx.data.User.id}`
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h6", null, _toDisplayString(_ctx.data.User.email), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "text-gray" }, "Name", -1)),
                      _createVNode(_component_router_link, {
                        to: `/users/${_ctx.data.User.id}`
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h6", null, _toDisplayString(_ctx.data.User.Profile.first_name) + " " + _toDisplayString(_ctx.data.User.Profile.last_name), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "text-gray" }, "Comment", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.description ? _ctx.data.description : "No Description"), 1)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "text-gray" }, "Remark", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.remark ? _ctx.data.remark : "No Remark"), 1)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-center" }, [
                    _createElementVNode("h6", { class: "text-gray" }, "Amount Information")
                  ], -1)),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _cache[10] || (_cache[10] = _createElementVNode("h4", { class: "text-gray" }, "Code", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.FiatCurrency.code), 1)
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _cache[11] || (_cache[11] = _createElementVNode("h4", { class: "text-gray" }, "Name", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.FiatCurrency.name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _cache[12] || (_cache[12] = _createElementVNode("h4", { class: "text-gray" }, "Amount", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.amount), 1)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _cache[16] || (_cache[16] = _createElementVNode("div", { class: "text-center" }, [
                    _createElementVNode("h6", { class: "text-gray" }, "Credit Information")
                  ], -1)),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _cache[14] || (_cache[14] = _createElementVNode("h4", { class: "text-gray" }, "Account Number", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.BankAccount.account_number), 1)
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _cache[15] || (_cache[15] = _createElementVNode("h4", { class: "text-gray" }, "Bank Code", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.BankAccount.bank_code), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.BankAccount.details, (value, key) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "col-4",
                        key: key
                      }, [
                        _createElementVNode("h4", _hoisted_30, _toDisplayString(key), 1),
                        _createElementVNode("h6", null, _toDisplayString(value), 1)
                      ]))
                    }), 128))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _cache[18] || (_cache[18] = _createElementVNode("div", { class: "text-center" }, [
                    _createElementVNode("h6", { class: "text-gray" }, "Payment Attempts")
                  ], -1)),
                  _createElementVNode("div", _hoisted_33, [
                    _createElementVNode("table", _hoisted_34, [
                      _cache[17] || (_cache[17] = _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, "#"),
                          _createElementVNode("th", null, "Reference"),
                          _createElementVNode("th", null, "FLW ID"),
                          _createElementVNode("th", null, "Status"),
                          _createElementVNode("th", null, "Date")
                        ])
                      ], -1)),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.PaymentOutwards, (dt, index) => {
                          return (_openBlock(), _createElementBlock("tr", { key: index }, [
                            _createElementVNode("td", null, _toDisplayString(dt.id), 1),
                            _createElementVNode("td", null, _toDisplayString(dt.reference), 1),
                            _createElementVNode("td", null, _toDisplayString(dt.flw_tx_id), 1),
                            _createElementVNode("td", null, _toDisplayString(dt.status), 1),
                            _createElementVNode("td", null, _toDisplayString(dt.created_at), 1)
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_35, _cache[19] || (_cache[19] = [
              _createElementVNode("div", { class: "col-12" }, [
                _createElementVNode("h1", { class: "text-danger text-center" }, "No Data!")
              ], -1)
            ])))
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}